import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

const ListItem = styled.div`
  margin-bottom: 20px;
`

const Title = styled.h2`
  text-transform: uppercase;
  border-top: 1px solid #cdcdcd;
  border-bottom: 1px solid #cdcdcd;
  padding: 12px 0;
  margin: 0 0 25px;
  font-size: 1.3rem;
`

const Image = styled(Img)`
  float: left;
  width: 25%;
`

const Text = styled.div`
  float: left;
  width: 75%;
  padding-left: 20px;
`

export default ({ item, basePath }) => {
  const image = item.frontmatter.listingImage || item.frontmatter.image

  return (
    <ListItem className="cf">
      <Title>{item.frontmatter.title}</Title>
      {image && (
        <Image sizes={{ ...image.childImageSharp.fluid, aspectRatio: 1 / 1 }} />
      )}
      <Text>
        <strong>{item.frontmatter.articleDate}</strong>
        <p>{item.frontmatter.listingText || item.excerpt}</p>
        <Link to={`${basePath}/${item.frontmatter.slug}`}>Read more ></Link>
      </Text>
    </ListItem>
  )
}
