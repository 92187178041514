import React from "react"
import { graphql, StaticQuery } from "gatsby"
import ListItem from "../list-item"

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/(books)/.*.md$/" } }
            sort: { fields: [frontmatter___year], order: DESC }
          ) {
            edges {
              node {
                frontmatter {
                  slug
                  title
                  image {
                    childImageSharp {
                      fluid(maxWidth: 200) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  listingText
                  year
                }
                excerpt
              }
            }
          }
        }
      `}
      render={data =>
        data.allMarkdownRemark.edges.map(data => (
          <ListItem
            key={data.node.frontmatter.slug}
            item={data.node}
            basePath="books"
          />
        ))
      }
    />
  )
}
